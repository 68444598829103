<template>
  <div class="w-full flex">
    <div
      class="h-dot w-dot"
      v-if="withEnds"
      :style="{
        backgroundImage: 'url(' + require('@/assets/dotted-left.png') + ')'
      }"
    />
    <div
      class="flex-1 h-dot bg-white"
      :style="{
        backgroundImage: 'url(' + require('@/assets/dotted-repeat.png') + ')'
      }"
    />
    <div
      class="h-dot w-dot"
      v-if="withEnds"
      :style="{
        backgroundImage: 'url(' + require('@/assets/dotted-right.png') + ')'
      }"
    />
  </div>
</template>

<script>
export default {
  name: 'DottedLine',
  props: {
    withEnds: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
.h-dot {
  height: 12px;
}
.w-dot {
  width: 6px;
}
</style>
