<template>
  <div
    class="absolute top-0 h-screen w-full z-20 transparent-background overflow-hidden"
  >
    <icon
      name="close"
      class="text-white float-right m-6 relative z-20"
      @click.native="$emit('close')"
    />
    <div
      class="mx-32 flex flex-col items-center overflow-scroll scrolling-touch h-full hide-scroll smooth"
      v-if="selectedModifiers.length > 0"
      id="groups"
      ref="groups"
    >
      <div style="height: 50%" class="flex-none" />
      <modifier-group-card
        @click.native="modifierGroup.id != activeGroupId && scrollTo(index)"
        v-for="(modifierGroup, index) in product.modifierGroups"
        :disabled="modifierGroup.id != activeGroupId"
        :key="modifierGroup.id"
        @next="scrollTo(index + 1)"
        :id="'group-' + index"
        :modifier-group="modifierGroup"
        :modifiers.sync="selectedModifiers[index]"
        :show-next="index + 1 < product.modifierGroups.length"
      />
      <div
        class="m-6 px-6 py-3 bg-red text-white rounded-small uppercase add-button"
        @click="saveProduct"
        :class="{ 'opacity-50 pointer-events-none': !canSaveProduct }"
      >
        {{ isEditing ? 'Guardar Cambios' : 'Agregar Producto' }}
      </div>
      <div style="height: 50%" class="flex-none" />
    </div>
    <div class="bottom-gradient absolute bottom-0 w-full z-10" />
    <div class="top-gradient absolute top-0 w-full z-10" />
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
import ModifierGroupCard from '@/components/ModifierGroupCard.vue'
import { debounce } from 'throttle-debounce'

export default {
  name: 'ModifiersSelector',
  props: {
    product: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      activeGroupId: null,
      selectedModifiers: [],
      scrollListener: debounce(100, this.onScroll)
    }
  },
  mounted() {
    this.selectedModifiers = Array.from(
      { length: this.product.modifierGroups.length },
      () => ({})
    )
    this.activeGroupId = this.product.modifierGroups[0].id
    if (this.product.modifiers) {
      let modifierIndexes = this.product.modifierGroups
        .flatMap((group, index) =>
          group.modifiers.map(modifier => ({ id: modifier.id, index }))
        )
        .reduce((res, modifier) => {
          res[modifier.id] = modifier.index
          return res
        }, {})
      this.product.modifiers.forEach(modifier => {
        let index = modifierIndexes[modifier.id]
        this.selectedModifiers[index][modifier.id] = modifier.quantity
      })
    }
    this.$nextTick(() => {
      this.$refs.groups.addEventListener('scroll', this.scrollListener)
      this.scrollTo(0)
    })
  },
  beforeDestroy() {
    this.$refs.groups.removeEventListener('scroll', this.scrollListener)
  },
  methods: {
    scrollTo(position) {
      if (position >= this.product.modifierGroups.length) return
      let scrollContainer = this.$refs.groups
      let middle = scrollContainer.getBoundingClientRect().height / 2
      let group = document.getElementById(`group-${position}`)
      let centerTop =
        group.getBoundingClientRect().y -
        middle +
        group.getBoundingClientRect().height / 2
      let realTop = group.getBoundingClientRect().y - 100
      let y = Math.min(centerTop, realTop)
      this.activeGroupId = this.product.modifierGroups[position].id
      scrollContainer.scrollBy({ top: y, behavior: 'smooth' })
    },
    onScroll() {
      this.activeGroupId = this.getItemInsideWindow()
    },
    getItemInsideWindow() {
      let ids = this.product.modifierGroups.map(group => group.id)
      let scrollContainer = this.$refs.groups
      let middle = scrollContainer.getBoundingClientRect().height / 2
      let distances = ids.map((id, index) => {
        const target = document.getElementById('group-' + index)
        if (!target) return null
        let box = target.getBoundingClientRect()
        let dist = Math.min(
          Math.abs(box.y - middle),
          Math.abs(box.y + box.height - middle)
        )
        return { id, dist }
      })
      distances.sort((a, b) => a.dist - b.dist)
      return distances[0].id
    },
    saveProduct() {
      let modifiers = this.product.modifierGroups
        .flatMap(group => group.modifiers)
        .reduce((res, modifier) => {
          res[modifier.id] = modifier
          return res
        }, {})
      let selectedModifiers = this.selectedModifiers.flatMap(groupModifiers =>
        Object.keys(groupModifiers).map(modifierId => ({
          ...modifiers[modifierId],
          quantity: groupModifiers[modifierId]
        }))
      )
      this.$emit('addProduct', {
        ...this.product,
        modifiers: selectedModifiers
      })
    }
  },
  computed: {
    offset() {
      return -(this.$el.clientHeight / 2 - 100)
    },
    canSaveProduct() {
      return this.product.modifierGroups.every((group, index) => {
        let totalModifiers = Object.values(
          this.selectedModifiers[index] || {}
        ).reduce((total, value) => (total += value), 0)
        let minCheck = totalModifiers >= (group.min || 0)
        let maxCheck = !group.max || totalModifiers <= group.max
        return minCheck && maxCheck
      })
    },
    isEditing() {
      return !!this.product.catalogId
    }
  },
  components: {
    Icon,
    ModifierGroupCard
  }
}
</script>

<style scoped>
.smooth {
  scroll-behavior: smooth;
}
.transparent-background {
  background-color: rgba(30, 32, 31, 0.9);
}
.bg-red {
  background: #dd3333;
}
.clear-button:disabled {
  @apply bg-gray-200;
  @apply text-gray-400;
}
.finish-button:disabled {
  @apply opacity-50;
}
.bottom-gradient {
  pointer-events: none;
  height: 10%;
  background-image: linear-gradient(
    to top,
    rgba(30, 32, 31, 0.9),
    rgba(30, 32, 31, 0)
  );
}
.top-gradient {
  pointer-events: none;
  height: 10%;
  background-image: linear-gradient(
    to bottom,
    rgba(30, 32, 31, 0.9),
    rgba(30, 32, 31, 0)
  );
}
.hide-scroll::-webkit-scrollbar {
  display: none;
}
</style>
