import idleJs from 'idle-js'
const seconds = process.env.VUE_APP_IDLE_TIMEOUT

const idleMixin = {
  data() {
    return {
      idlejs: null,
      idleActive: false
    }
  },
  mounted() {
    this.initIdle()
  },
  beforeDestroy() {
    if (this.idlejs) {
      this.idlejs.stop()
    }
  },

  methods: {
    initIdle() {
      this.idlejs = this.createIdle(seconds)
      this.idlejs.start()
    },
    activateIdle() {
      this.idleActive = true
    },
    deactivateIdle() {
      if (this.idlejs) {
        this.idlejs.stop()
      }
      this.initIdle()
      this.idleActive = false
    },
    createIdle(seconds) {
      return new idleJs({
        idle: seconds * 1000,
        events: ['mousemove', 'keydown', 'mousedown', 'touchstart', 'click'],
        onIdle: () => {
          this.activateIdle()
        }
      })
    }
  }
}

export default idleMixin
