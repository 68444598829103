var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card shadow rounded-big p-4 relative line-height"},[_c('div',{staticClass:"absolute rounded-full w-6 h-6 bg-black p-small m-3 top-0 right-0",on:{"click":function($event){$event.stopPropagation();return _vm.deleteProduct.apply(null, arguments)}}},[_c('icon',{staticClass:"w-full h-full text-white",attrs:{"name":"close"}})],1),_c('div',{staticClass:"flex flex-col h-full"},[_c('div',{staticClass:"flex justify-between items-center",on:{"click":function($event){;(_vm.product.modifierGroups || _vm.product.comboProducts) &&
          (_vm.product.modifierGroups.length > 0 ||
            _vm.product.comboProducts.length > 0) &&
          _vm.$emit('edit')}}},[_c('div',{staticClass:"font-title text-accent text-xl uppercase mb-2"},[_vm._v(" "+_vm._s(_vm.product.name)+" ")])]),_c('div',{staticClass:"text-gray-400 flex-1 pt-2 overflow-y-scroll scrolling-touch",on:{"click":function($event){_vm.product.modifierGroups &&
          _vm.product.modifierGroups.length > 0 &&
          _vm.$emit('edit')}}},[_vm._v(" "+_vm._s(_vm.modifiers)+" ")]),_c('div',{staticClass:"text-gray-400 flex-1 pt-2 overflow-y-scroll scrolling-touch",on:{"click":function($event){_vm.product.comboProducts &&
          _vm.product.comboProducts.length > 0 &&
          _vm.$emit('edit')}}},[_vm._v(" "+_vm._s(_vm.comboProducts)+" ")]),_c('div',{staticClass:"mt-6 flex justify-between items-center"},[_c('quantity-selector',{attrs:{"quantity":_vm.product.quantity},on:{"update:quantity":_vm.updateQuantity}}),_c('div',{staticClass:"text-2xl text-gray-900 font-bold font-title"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.product.fullPrice))+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }