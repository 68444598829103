<template>
  <div id="app" :style="cssProps">
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapGetters('config', ['customization']),
    cssProps() {
      return {
        '--brand-font': this.customization.fontFamily,
        '--accent-color': this.customization.accentColor
      }
    }
  }
}
</script>

<style></style>
