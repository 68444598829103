import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import './registerServiceWorker'
import './style.css'
import './filters.js'
import VueScrollTo from 'vue-scrollto'
import smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()

Vue.use(VueScrollTo)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
