import api from '@/api.js'

const state = {
  accessToken: localStorage.getItem('accessToken'),
  locationId: localStorage.getItem('locationId'),
  organizationId: localStorage.getItem('organizationId'),
  currencyCode: localStorage.getItem('currencyCode')
}

const getters = {
  isAuthenticated: state => !!state.accessToken
}

const actions = {
  async login({ commit }, loginData) {
    let response = await api.post('/users/login', loginData)
    commit('setAccessToken', response.data.token)
  },
  selectOrganizationId({ commit }, organizationId) {
    commit('selectOrganizationId', organizationId)
  },
  selectLocationId({ commit }, locationId) {
    commit('setLocationId', locationId)
  },
  selectCurrencyCode({ commit }, currencyCode) {
    commit('setCurrencyCode', currencyCode)
  }
}

const mutations = {
  setAccessToken(state, token) {
    state.accessToken = token
    localStorage.setItem('accessToken', token)
  },
  selectOrganizationId(state, organizationId) {
    state.organizationId = organizationId
    localStorage.setItem('organizationId', organizationId)
  },
  setLocationId(state, locationId) {
    state.locationId = locationId
    localStorage.setItem('locationId', locationId)
  },
  selectCurrencyCode(state, currencyCode) {
    state.currencyCode = currencyCode
    localStorage.setItem('currencyCode', currencyCode)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
