<template>
  <div class="flex w-full px-32 max-w-6xl justify-center">
    <div
      class="bg-white rounded-big p-12 flex justify-center flex-col text-center card"
    >
      <img src="@/assets/error.svg" width="100" class="pb-6 m-auto" />
      <div class="text-2xl font-title">¡ERROR AL REALIZAR EL PAGO!</div>
      <div class="pt-2 text-sm text-gray-500">
        Ha habido un error al momento de realizar el pago con su tarjeta, por
        favor<br />inténtelo de nuevo o diríjase al mostrador.
      </div>
      <div class="pt-4">
        <button
          class="m-6 px-6 py-3 bg-accent text-white rounded-small uppercase finish-button transition"
          @click="$emit('close')"
        >
          Volver al carrito
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutError'
}
</script>

<style scoped>
.card {
  width: 750px;
}
.rounded-big-top {
  border-radius: 20px 20px 0 0;
}
.rounded-big-bottom {
  border-radius: 0 0 20px 20px;
}
</style>
