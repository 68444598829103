<template>
  <div class="flex w-full px-32 max-w-6xl justify-center">
    <div
      class="bg-white rounded-big p-12 bg-image flex justify-center flex-col text-center card uppercase text-2xl"
    >
      <div class="text-big font-title pb-10">Procesando el pago</div>
      <l-loading-spinner size="medium" theme="dark" class="checkout__spinner" />
    </div>
  </div>
</template>

<script>
import LLoadingSpinner from '@last/core-ui/components/LLoadingSpinner.vue'

export default {
  name: 'CheckoutProcessing',
  components: {
    LLoadingSpinner
  }
}
</script>

<style lang="scss" scoped>
.checkout__spinner {
  left: 50%;
  margin-left: -20px;
  top: 50%;
  margin-top: 14px;
  position: absolute;
}
</style>
