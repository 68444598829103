<template>
  <div class="flex flex-col w-full px-32 max-w-6xl">
    <div class="text-white text-4xl uppercase font-title pb-6 text-center">
      Checkout de tu pedido
    </div>
    <div class="bg-white rounded-big p-12">
      <div class="text-accent text-3xl uppercase font-title p-6 text-center">
        ¿COMO PREFIERES QUE TE AVISEMOS CUANDO<br />TU PEDIDO ESTE LISTO?
      </div>
      <div
        @click="method = 'sms'"
        class="rounded-big shadow p-6 m-8 border border-white"
        :class="{
          'border-accent': method === 'sms',
          'opacity-50': method != null && method != 'sms'
        }"
      >
        <div class="flex items-center">
          <l-radio :value="method === 'sms'" @input="method = 'sms'" />
          <div
            class="pl-4 uppercase font-title font-bold text-accent text-xl text-center"
          >
            Avisarme por SMS
          </div>
        </div>
        <div class="text-gray-500 text-sm pt-2">
          ¡Puedes ir a dar una vuelta mientras preparamos tu pedido! Te
          avisaremos por SMS.
        </div>
        <div class="pt-3">
          <l-input
            icon="phone"
            icon-class="text-gray-900"
            type="tel"
            placeholder="Número de teléfono"
            v-model="phoneNumber"
            big
          />
        </div>
      </div>
      <div
        @click="method = 'name'"
        class="rounded-big shadow p-6 m-8 border border-white"
        :class="{
          'border-accent': method === 'name',
          'opacity-50': method != null && method != 'name'
        }"
      >
        <div class="flex items-center">
          <l-radio :value="method === 'name'" @input="method = 'name'" />
          <div
            class="pl-4 uppercase font-title font-bold text-accent text-xl text-center"
          >
            Avisarme con mi nombre
          </div>
        </div>
        <div class="text-gray-500 text-sm pt-2">
          Te llamaremos por tu nombre pero ¡deberás quedarte cerca para poder
          escucharlo!
        </div>
        <div class="pt-3">
          <l-input
            icon="man"
            icon-class="text-gray-900"
            placeholder="Nombre y apellido"
            v-model="name"
            big
          />
        </div>
      </div>
      <div v-if="error" class="text-sm text-red pt-3 text-center">
        Por favor revisa los datos, parece que no son correctos
      </div>
    </div>
    <div class="flex justify-end">
      <button
        class="my-6 mx-4 px-6 py-3 text-white rounded-small uppercase finish-button font-title border border-white"
        @click="$emit('cancel')"
      >
        Cancel
      </button>
      <button
        class="my-6 px-6 py-3 bg-accent text-white rounded-small uppercase finish-button font-title"
        @click="accept()"
      >
        Aceptar
      </button>
    </div>
  </div>
</template>

<script>
import LInput from '@last/core-ui/components/LInput.vue'
import LRadio from '@last/core-ui/components/LRadio.vue'
import validator from 'validator'

export default {
  name: 'CheckoutContact',
  data() {
    return {
      phoneNumber: null,
      email: null,
      name: null,
      error: false,
      method: null
    }
  },
  methods: {
    accept() {
      let hasPhone =
        this.phoneNumber && validator.isMobilePhone(this.phoneNumber)
      if (this.method === 'sms' && hasPhone) {
        this.$emit('accept', {
          phoneNumber: this.phoneNumber
        })
      } else if (this.method === 'name' && this.name) {
        this.$emit('accept', {
          name: this.name
        })
      } else {
        this.error = true
      }
    }
  },
  components: {
    LInput,
    LRadio
  }
}
</script>

<style scoped>
.card {
  max-width: 750px;
}
.rounded-big-top {
  border-radius: 20px 20px 0 0;
}
.rounded-big-bottom {
  border-radius: 0 0 20px 20px;
}
</style>
