<template>
  <div class="flex items-center">
    <div
      class="rounded-full w-6 h-6 bg-black p-1 mr-4"
      :class="{ 'bg-gray-300': quantity === min }"
      @click.stop="subtract"
    >
      <icon name="minus" class="w-full h-full text-white" />
    </div>
    {{ quantity }}
    <div
      class="rounded-full w-6 h-6 bg-black p-1 ml-4"
      :class="{ 'bg-gray-300': isMaxed }"
      @click.stop="add"
    >
      <icon name="plus" class="w-full h-full text-white" />
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'

export default {
  name: 'QuantitySelector',
  props: {
    quantity: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: 1
    },
    isMaxed: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    add() {
      if (!this.isMaxed) {
        this.$emit('update:quantity', this.quantity + 1)
      }
    },
    subtract() {
      if (this.quantity > this.min) {
        this.$emit('update:quantity', this.quantity - 1)
      }
    }
  },
  components: {
    Icon
  }
}
</script>
