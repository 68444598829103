var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card m-3"},[_c('div',{staticClass:"h-full rounded-big overflow-hidden bg-white relative"},[_c('div',{staticClass:"flex flex-col h-full transition z-10 relative",class:{ 'transparent-background': _vm.showingDescription }},[_c('div',{staticClass:"transition z-10 p-2 flex justify-end",class:{
          'image-height': !_vm.showingDescription,
          'description-top': _vm.showingDescription
        },on:{"click":function($event){_vm.showingDescription = true}}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.showingDescription)?_c('icon',{staticClass:"text-white",attrs:{"name":"close"},nativeOn:{"click":function($event){$event.stopPropagation();_vm.showingDescription = false}}}):_vm._e()],1)],1),_c('div',{staticClass:"h-full p-4 flex-1 flex flex-col relative z-10"},[_c('div',{staticClass:"text-lg font-title transition uppercase line-h flex-shrink-0",class:{
            'text-white max-name-h100': _vm.showingDescription,
            'max-name-h': !_vm.showingDescription
          }},[_vm._v(" "+_vm._s(_vm.product.name)+" ")]),_c('transition',{attrs:{"name":"fade-delay"}},[(_vm.showingDescription)?_c('div',[_c('div',{staticClass:"separator my-2"}),_c('div',{staticClass:"text-sm text-white overflow-y-scroll scrolling-touch max-description-h"},[_vm._v(" "+_vm._s(_vm.product.description)+" ")])]):_vm._e()]),_c('div',{staticClass:"flex-1 flex flex-col justify-end items-end"},[_c('transition',{attrs:{"name":"fade"}},[(!_vm.showingDescription)?_c('div',{staticClass:"font-bold text-2xl"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.product.price))+" ")]):_vm._e()]),_c('div',{staticClass:"w-full pt-2 relative"},[_c('transition',{attrs:{"name":"fade"}},[(!_vm.showingDescription)?_c('div',{staticClass:"bg-gray-900 p-2 rounded-small mr-2 absolute",on:{"click":function($event){_vm.showingDescription = true}}},[_c('icon',{staticClass:"text-white transition",class:{
                    'opacity-0': _vm.showingDescription,
                    'opacity-100': !_vm.showingDescription
                  },attrs:{"name":"eye"}})],1):_vm._e()]),_c('div',{staticClass:"flex-1 p-2 text-white bg-accent rounded-small font-title text-center transition z-10 relative uppercase",class:{ 'button-margin': !_vm.showingDescription },on:{"click":function($event){return _vm.$emit('add', _vm.product)}}},[_vm._v(" Añadir ")])],1)],1)],1)]),_c('div',{staticClass:"absolute top-0 left-0 z-0 w-full h-full"},[(_vm.product.imageId)?_c('l-image',{staticClass:"flex-1 p-4 bg-cover bg-center transition-size",class:{
          'h-full': _vm.showingDescription,
          'image-height': !_vm.showingDescription
        },attrs:{"image-id":_vm.product.imageId,"background":""}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }