var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"rounded-full w-6 h-6 p-1 cursor-pointer flex-shrink-0 flex items-center justify-center",class:{
      'opacity-50': _vm.quantity === _vm.min,
      'bg-accent bg-red': _vm.styleProp === 'primary',
      'border border-secondary border-blue': _vm.styleProp === 'secondary'
    },on:{"click":function($event){$event.stopPropagation();return _vm.subtract.apply(null, arguments)}}},[_c('icon',{staticClass:"w-full h-full",class:{
        'text-secondary text-blue': _vm.styleProp === 'secondary',
        'text-white': _vm.styleProp === 'primary'
      },attrs:{"name":"minus","small":""}})],1),_c('div',{staticClass:"mx-2",class:{
      'text-secondary text-blue': _vm.styleProp === 'secondary',
      'text-accent text-red': _vm.styleProp === 'primary'
    }},[_vm._v(" "+_vm._s(_vm.quantity)+" ")]),_c('div',{staticClass:"rounded-full w-6 h-6 p-1 cursor-pointer flex-shrink-0 flex items-center justify-center",class:{
      'opacity-50': _vm.isMaxed,
      'bg-accent bg-red': _vm.styleProp === 'primary',
      'border border-secondary border-blue': _vm.styleProp === 'secondary'
    },on:{"click":function($event){$event.stopPropagation();return _vm.add.apply(null, arguments)}}},[_c('icon',{staticClass:"w-full h-full",class:{
        'text-secondary text-blue': _vm.styleProp === 'secondary',
        'text-white': _vm.styleProp === 'primary'
      },attrs:{"name":"plus","small":""}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }