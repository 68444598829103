<template>
  <div
    class="flex w-full px-32 max-w-6xl justify-center"
    @click="goToWelcome()"
  >
    <div
      :style="bgStyle"
      class="bg-white rounded-big p-12 bg-cover flex justify-center flex-col text-center card"
    >
      <img :src="customization.logoUrl" class="pb-6 m-auto" width="300" />
      <div class="text-big font-title">¡GRACIAS POR SU COMPRA!</div>
      <div class="pt-6 text-lg font-bold">SU Nº DE PEDIDO ES EL</div>
      <div class="text-xbig font-title">{{ number }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CheckoutDone',
  props: {
    number: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      timeout: null
    }
  },
  mounted() {
    this.timeout = setTimeout(() => {
      this.goToWelcome()
    }, 4000)
  },
  destroyed() {
    clearTimeout(this.timeout)
  },
  methods: {
    goToWelcome() {
      this.$router.push({ name: 'welcome' })
    }
  },
  computed: {
    ...mapGetters('config', ['customization']),
    bgStyle() {
      if (!this.customization.doneBackgroundImageUrl) return {}
      return {
        backgroundImage: `url(${this.customization.doneBackgroundImageUrl})`
      }
    }
  }
}
</script>

<style scoped>
.card {
  width: 750px;
}
.rounded-big-top {
  border-radius: 20px 20px 0 0;
}
.rounded-big-bottom {
  border-radius: 0 0 20px 20px;
}
.bg-image {
  background-image: url('../assets/done-bg.png');
  background-size: cover;
}
.text-big {
  font-size: 44px;
}
.text-xbig {
  font-size: 88px;
}
</style>
