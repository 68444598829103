<template>
  <div
    class="rounded-big w-full bg-white p-10 my-5 card relative transition"
    :class="{ disabled: disabled }"
  >
    <div class="font-title text-xl uppercase">{{ category.name }}</div>
    <div class="flex justify-between items-center">
      <div class="text-sm text-gray-400">{{ subtitle }}</div>
      <div class="text-sm text-red" v-if="category.min">*obligatorio</div>
    </div>

    <div class="pt-6">
      <div
        v-for="product in filteredProducts"
        :key="product.id"
        class="rounded-small border-gray-400 mb-4"
        @click="toggle(product.id)"
        :class="{
          'border border-red': isSelected(product.id),
          border: !isSelected(product.id),
          'text-gray-300 pointer-events-none':
            isMaxed && multiple && !isSelected(product.id)
        }"
      >
        <div class="flex items-center justify-between p-4">
          <div class="flex items-center">
            <l-checkbox
              v-if="multiple"
              :value="isSelected(product.id)"
              @input="toggle(product.id)"
            />
            <l-radio
              v-else
              :value="isSelected(product.id)"
              @input="toggle(product.id)"
            />
            <div class="ml-4">{{ product.name }}</div>
          </div>
          <div class="text-sm text-red" v-if="product.priceImpact > 0">
            (+{{ product.priceImpact | currency }})
          </div>
          <quantity-selector
            class="ml-4"
            v-if="category.max > 1 && isSelected(product.id)"
            :quantity="products[product.id].quantity"
            @update:quantity="quantity => updateQuantity(product.id, quantity)"
            :is-maxed="isMaxed"
          />
        </div>
        <div
          v-if="isSelected(product.id)"
          class="pl-14 pr-4 bg-gray-100 rounded-small"
          @click.stop
        >
          <modifier-groups
            :modifier-groups="product.modifierGroups"
            :modifiers.sync="products[product.id].modifiers"
            :valid.sync="products[product.id].validModifiers"
            @update:valid="$emit('update:products', products)"
          />
        </div>
      </div>
    </div>
    <transition name="grow">
      <div
        class="w-full absolute bottom-0 left-0 -mb-6 flex justify-center"
        v-if="canContinue && showNext"
      >
        <div
          class="bg-red w-12 h-12 rounded-full flex justify-center items-center"
          @click.stop="$emit('next')"
        >
          <icon name="arrow-down" class="text-white" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import LCheckbox from '@last/core-ui/components/LCheckbox.vue'
import LRadio from '@last/core-ui/components/LRadio.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import QuantitySelector from './QuantitySelector.vue'
import ModifierGroups from './ModifierGroups.vue'

export default {
  name: 'ComboCategory',
  props: {
    category: {
      type: Object,
      default: null
    },
    products: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showNext: {
      type: Boolean,
      default: false
    }
  },
  mounted() {},
  methods: {
    toggle(id) {
      if (this.disabled) return
      if (this.multiple) {
        if (id in this.products) {
          let products = {
            ...this.products
          }
          delete products[id]
          this.$emit('update:products', products)
        } else {
          this.$emit('update:products', {
            ...this.products,
            [id]: { quantity: 1, modifiers: [], validModifiers: false }
          })
        }
      } else {
        this.$emit('update:products', {
          [id]: { quantity: 1, modifiers: [], validModifiers: false }
        })
      }
    },
    isSelected(id) {
      if (!this.products) {
        return false
      }
      return id in this.products
    },
    updateQuantity(id, quantity) {
      let products = {
        ...this.products
      }
      products[id].quantity = quantity
      this.$emit('update:products', products)
    }
  },
  computed: {
    filteredProducts() {
      return this.category?.products?.filter(p => p.enabled)
    },
    multiple() {
      let min = this.category.min || 0
      let max = this.category.max
      if (!this.category.max) return true
      if (min > 1) return true
      return max - min > 0
    },
    subtitle() {
      let subtitle = 'Seleccionar'
      if (!this.multiple) {
        return subtitle + ' una opción'
      }
      if (this.category.min) {
        subtitle += ` un mínimo de ${this.category.min}`
      }
      if (this.category.min && this.category.max) {
        subtitle += ' y'
      }
      if (this.category.max) {
        subtitle += ` un máximo de ${this.category.max}`
      }
      return subtitle + ' opciones'
    },
    totalProducts() {
      if (!this.products) {
        return 0
      }
      return Object.values(this.products).reduce(
        (res, { quantity }) => res + quantity,
        0
      )
    },
    canContinue() {
      return this.totalProducts >= this.category.min
    },
    isMaxed() {
      return this.category.max && this.totalProducts === this.category.max
    }
  },
  watch: {
    isMaxed(isMaxed) {
      if (isMaxed) {
        this.$emit('next')
      }
    }
  },
  components: {
    LCheckbox,
    LRadio,
    Icon,
    QuantitySelector,
    ModifierGroups
  }
}
</script>

<style scoped>
.pl-14 {
  padding-left: 3.5rem;
}
</style>
