import axios from 'axios'
import store from '@/store/index'

let api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
})

api.interceptors.request.use(
  config => {
    let token = store.state.auth.accessToken

    if (token) {
      config.headers['Authorization'] = token
    }

    let locationId = store.state.auth.locationId
    if (locationId) {
      config.headers['Location-ID'] = locationId
    }

    return config
  },

  error => {
    return Promise.reject(error)
  }
)

export default api
