<template>
  <div
    class="bg-cover bg-center h-screen w-full flex items-end p-10"
    :style="{ backgroundImage: `url(${customization.backgroundImageUrl})` }"
    @click="$router.push({ name: 'ordering' })"
  >
    <div
      class="py-20 rounded-big w-full flex justify-center transparent-background"
    >
      <div class="w-1/2 uppercase text-white text-center font-big font-title">
        Toca la pantalla para iniciar la orden
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Welcome',
  computed: {
    ...mapGetters('config', ['customization'])
  }
}
</script>

<style>
.transparent-background {
  backdrop-filter: blur(10px);
  background-color: rgba(30, 32, 31, 0.8);
}
.font-big {
  font-size: 44px;
}
</style>
