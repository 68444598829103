import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/components/Login.vue'
import Welcome from '@/components/Welcome.vue'
import LocationSelector from '@/components/LocationSelector.vue'
import Ordering from '@/components/Ordering.vue'
import store from '@/store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    name: 'locationSelector',
    path: '/',
    component: LocationSelector
  },
  {
    name: 'welcome',
    path: '/welcome',
    component: Welcome
  },
  {
    name: 'ordering',
    path: '/ordering',
    component: Ordering
  },
  {
    name: 'login',
    path: '/login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  let needsAuth = !['login', 'signUp'].includes(to.name)
  let isAuthenticated = store.getters['auth/isAuthenticated']
  if (needsAuth && !isAuthenticated) {
    next({
      name: 'login',
      query: { redirect: to.fullPath }
    })
  } else {
    next()
  }
})

export default router
