<template>
  <div class="flex flex-col w-full px-32 max-w-6xl">
    <div class="text-white text-4xl uppercase font-title pb-6 text-center">
      Checkout de tu pedido
    </div>
    <div class="bg-white rounded-big-top py-4" />
    <checkout-product
      v-for="product in products"
      :key="product.id"
      :product="product"
      @updateQuantity="quantity => updateQuantity(product.id, quantity)"
    />
    <div class="flex justify-end items-baseline py-8 px-6 bg-white">
      <div class="text-gray-400 text-md pr-4">Total</div>
      <div class="text-2xl text-accent font-title font-bold">
        {{ total | currency }}
      </div>
    </div>

    <div class="pt-8 pb-4 px-6 bg-white">
      <h2 class="font-title text-3xl uppercase">Alergias</h2>
      <textarea
        v-model="comment"
        class="border-gray-400 border"
        maxlength="255"
        placeholder="¿Eres alérgico a algún alimento?"
      />
    </div>

    <div class="pb-4 px-6 bg-white rounded-big-bottom">
      <h2 class="pb-3 font-title text-3xl uppercase">Elige la forma de pago</h2>
      <div
        @click="setPaymentMethod('cash')"
        :class="{
          'border border-accent': paymentMethod === 'cash'
        }"
        class="rounded-small border border-gray-400 p-4 mb-4 flex items-center justify-between"
      >
        <div class="flex items-center">
          <l-radio
            :value="paymentMethod === 'cash'"
            @input="setPaymentMethod('cash')"
          />
          <div class="ml-4 text-gray-600">Cash</div>
        </div>
        <div class="flex items-center">
          <div class="text-sm text-accent">
            <icon name="cash" />
          </div>
        </div>
      </div>
      <div
        @click="setPaymentMethod('creditCard')"
        :class="{
          'border border-accent': paymentMethod === 'creditCard'
        }"
        class="rounded-small border border-gray-400 p-4 mb-4 flex items-center justify-between"
      >
        <div class="flex items-center">
          <l-radio
            :value="paymentMethod === 'creditCard'"
            @input="setPaymentMethod('creditCard')"
          />
          <div class="ml-4 text-gray-600">Pago con tarjeta</div>
        </div>
        <div class="flex items-center">
          <div class="text-sm text-accent">
            <icon name="card" />
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-end">
      <button
        class="my-6 mx-4 px-6 py-3 text-white rounded-small uppercase finish-button font-title border border-white"
        @click="$emit('close')"
      >
        Volver al menú
      </button>
      <button
        class="my-6 px-6 py-3 bg-accent text-white rounded-small uppercase finish-button font-title"
        @click="$emit('pay')"
      >
        Pagar
      </button>
    </div>
  </div>
</template>

<script>
import CheckoutProduct from './CheckoutProduct.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import LRadio from '@last/core-ui/components/LRadio'

export default {
  name: 'CheckoutSummary',
  components: {
    Icon,
    CheckoutProduct,
    LRadio
  },
  data: () => ({
    comment: '',
    paymentMethod: null
  }),
  props: {
    products: {
      type: Array,
      default: null
    }
  },
  methods: {
    updateQuantity(productId, quantity) {
      this.$emit('updateQuantity', { productId, quantity })
    },
    setPaymentMethod(paymentMethod) {
      if (this.paymentMethod === paymentMethod) this.paymentMethod = null
      else this.paymentMethod = paymentMethod
    }
  },
  computed: {
    total() {
      return this.products
        .map(product => product.fullPrice * product.quantity)
        .reduce((total, value) => total + value)
    }
  },
  watch: {
    comment() {
      this.$emit('update:comment', this.comment)
    },
    paymentMethod() {
      this.$emit('update:isCreditCard', this.paymentMethod === 'creditCard')
    }
  }
}
</script>

<style scoped>
.card {
  max-width: 750px;
}
.pay-button:disabled {
  @apply opacity-50;
}
.rounded-big-top {
  border-radius: 20px 20px 0 0;
}
.rounded-big-bottom {
  border-radius: 0 0 20px 20px;
}

textarea {
  width: 100%;
  min-height: 120px;
  border-radius: 10px;
  margin-top: 10px;
  padding: 20px;
  margin-bottom: 20px;
}
</style>
