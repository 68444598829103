<template>
  <div>
    <modifier-group-section
      v-for="(modifierGroup, index) in filteredModifierGroups"
      :key="modifierGroup.id"
      :modifier-group="modifierGroup"
      :modifiers.sync="selectedModifiers[index]"
    />
  </div>
</template>

<script>
import ModifierGroupSection from './ModifierGroupSection'

export default {
  name: 'ModifierGroups',
  data() {
    return {
      selectedModifiers: []
    }
  },
  props: {
    modifierGroups: {
      type: Array,
      default: () => []
    },
    modifiers: {
      type: Array,
      default: () => []
    },
    valid: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateSelectedModifiers() {
      this.selectedModifiers = Array.from(
        { length: this.modifierGroups.length },
        () => ({})
      )
      if (this.modifiers) {
        let modifierIndexes = this.modifierGroups
          .flatMap((group, index) =>
            group.modifiers.map(modifier => ({ id: modifier.id, index }))
          )
          .reduce((res, modifier) => {
            res[modifier.id] = modifier.index
            return res
          }, {})
        this.modifiers.forEach(modifier => {
          let index = modifierIndexes[modifier.id]
          this.selectedModifiers[index][modifier.id] = modifier.quantity
        })
      }
    }
  },
  mounted() {
    this.updateSelectedModifiers()
    this.$emit('update:valid', this.validModifiers)
  },
  computed: {
    filteredModifierGroups() {
      return this.modifierGroups?.filter(mg => mg.enabled)
    },
    validModifiers() {
      return this.filteredModifierGroups.every((group, index) => {
        let totalModifiers = Object.values(
          this.selectedModifiers[index] || {}
        ).reduce((total, value) => (total += value), 0)
        let minCheck = totalModifiers >= (group.min || 0)
        let maxCheck = !group.max || totalModifiers <= group.max
        return minCheck && maxCheck
      })
    },
    editingProductModifiers() {
      let modifiers = (this.modifierGroups || [])
        .flatMap(group => group.modifiers)
        .reduce((res, modifier) => {
          res[modifier.id] = modifier
          return res
        }, {})
      return this.selectedModifiers.flatMap(groupModifiers =>
        Object.keys(groupModifiers).map(modifierId => ({
          ...modifiers[modifierId],
          quantity: groupModifiers[modifierId]
        }))
      )
    }
  },
  watch: {
    validModifiers(validModifiers) {
      this.$emit('update:valid', validModifiers)
    },
    editingProductModifiers(modifiers) {
      this.$emit('update:modifiers', modifiers)
    }
  },
  components: {
    ModifierGroupSection
  }
}
</script>
