import { render, staticRenderFns } from "./CheckoutDone.vue?vue&type=template&id=165a444d&scoped=true&"
import script from "./CheckoutDone.vue?vue&type=script&lang=js&"
export * from "./CheckoutDone.vue?vue&type=script&lang=js&"
import style0 from "./CheckoutDone.vue?vue&type=style&index=0&id=165a444d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "165a444d",
  null
  
)

export default component.exports