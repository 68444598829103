<template>
  <div
    class="h-screen flex flex-col justify-center items-center bg-cover bg-gray-200"
  >
    <div>
      <div class="flex flex-wrap px-24 py-4 justify-center">
        <div
          v-for="location in locations"
          class="employee-box bg-white m-5 rounded-big uppercase font-title text-blue text-xl font-bold flex justify-center items-center shadow-xl"
          @click="selectLocationAndContinue(location)"
          :key="location.id"
        >
          {{ location.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api.js'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'LocationSelector',
  data() {
    return {
      locations: []
    }
  },
  async mounted() {
    if (this.locationId && this.organizationId) {
      this.$router.push({ name: 'welcome' })
    }
    let response = await api.get('/locations')
    this.locations = response.data
    if (this.locationId) {
      this.selectLocationAndContinue(
        this.locations.find(location => location.id === location.id)
      )
    } else if (this.locations.length === 1) {
      this.selectLocationAndContinue(this.locations[0])
    }
  },
  computed: {
    ...mapState('auth', ['locationId', 'organizationId'])
  },
  methods: {
    ...mapActions('auth', [
      'selectLocationId',
      'selectOrganizationId',
      'selectCurrencyCode'
    ]),
    selectLocationAndContinue(location) {
      this.selectOrganizationId(location.organizationId)
      this.selectLocationId(location.id)
      this.selectCurrencyCode(location.currencyCode)
      this.$router.push({ name: 'welcome' })
    }
  }
}
</script>

<style lang="scss" scoped>
.employee-box {
  width: 171px;
  height: 109px;
}
</style>
