<template>
  <div class="flex items-center">
    <div
      class="rounded-full w-6 h-6 p-1 cursor-pointer flex-shrink-0 flex items-center justify-center"
      :class="{
        'opacity-50': quantity === min,
        'bg-accent bg-red': styleProp === 'primary',
        'border border-secondary border-blue': styleProp === 'secondary'
      }"
      @click.stop="subtract"
    >
      <icon
        name="minus"
        class="w-full h-full"
        :class="{
          'text-secondary text-blue': styleProp === 'secondary',
          'text-white': styleProp === 'primary'
        }"
        small
      />
    </div>
    <div
      class="mx-2"
      :class="{
        'text-secondary text-blue': styleProp === 'secondary',
        'text-accent text-red': styleProp === 'primary'
      }"
    >
      {{ quantity }}
    </div>
    <div
      class="rounded-full w-6 h-6 p-1 cursor-pointer flex-shrink-0 flex items-center justify-center"
      :class="{
        'opacity-50': isMaxed,
        'bg-accent bg-red': styleProp === 'primary',
        'border border-secondary border-blue': styleProp === 'secondary'
      }"
      @click.stop="add"
    >
      <icon
        name="plus"
        class="w-full h-full"
        :class="{
          'text-secondary text-blue': styleProp === 'secondary',
          'text-white': styleProp === 'primary'
        }"
        small
      />
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'

export default {
  name: 'LQuantitySelector',
  props: {
    quantity: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: 1
    },
    isMaxed: {
      type: [Boolean, Number],
      default: false
    },
    buttonColor: {
      type: String,
      default: 'bg-secondary'
    },
    styleProp: {
      type: String,
      default: 'primary'
    }
  },
  methods: {
    add() {
      if (!this.isMaxed) {
        this.$emit('update:quantity', this.quantity + 1)
      }
    },
    subtract() {
      if (this.quantity > this.min) {
        this.$emit('update:quantity', this.quantity - 1)
      }
    }
  },
  components: {
    Icon
  }
}
</script>
