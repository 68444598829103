<template>
  <div
    class="absolute top-0 h-screen w-full z-20 transparent-background overflow-hidden flex flex-col justify-center items-center"
  >
    <div
      class="max-h-full overflow-y-scroll scrolling-touch w-full flex justify-center"
    >
      <checkout-summary
        v-if="state === 'summary'"
        :products="products"
        :comment.sync="comment"
        :is-credit-card.sync="isCreditCard"
        @close="$emit('close')"
        @updateQuantity="updateQuantity"
        @pay="state = 'contact'"
      />
      <checkout-contact
        v-if="state === 'contact'"
        @cancel="$emit('close')"
        @accept="pay"
      />
      <checkout-processing v-if="state === 'processing'" />
      <checkout-done v-if="state === 'done'" :number="number" />
      <checkout-error @close="$emit('close')" v-if="state === 'error'" />
    </div>
  </div>
</template>

<script>
import CheckoutSummary from './CheckoutSummary.vue'
import CheckoutContact from './CheckoutContact.vue'
import CheckoutDone from './CheckoutDone.vue'
import CheckoutError from './CheckoutError.vue'
import CheckoutProcessing from './CheckoutProcessing.vue'
import api from '@/api.js'

import { SumUpPlugin } from '@/SumUpPlugin.js'
import { mapState } from 'vuex'

export default {
  name: 'Checkout',
  props: {
    products: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      state: 'summary',
      number: null,
      comment: '',
      isCreditCard: true
    }
  },
  methods: {
    async pay(contact) {
      try {
        this.state = 'processing'
        if (this.isCreditCard === true)
          await SumUpPlugin.charge({
            amount: this.total,
            currencyCode: this.currencyCode
          })

        let products = this.products.map(product => ({
          name: product.name,
          id: product.catalogId,
          price: product.price,
          quantity: product.quantity,
          modifiers: this.mapModifiers(product.modifiers),
          comboProducts: product.comboProducts?.map(comboProduct => ({
            name: comboProduct.name,
            id: comboProduct.id,
            quantity: comboProduct.quantity,
            modifiers: this.mapModifiers(comboProduct.modifiers)
          }))
        }))
        let response = await api.post('/orders', {
          products,
          contact,
          allergyInfo: this.comment,
          paid: this.isCreditCard
        })
        this.number = response.data.number
        this.state = 'done'
      } catch (error) {
        this.state = 'error'
      }
    },
    mapModifiers(modifiers) {
      return modifiers.map(modifier => ({
        name: modifier.name,
        id: modifier.id,
        priceImpact: modifier.priceImpact,
        quantity: modifier.quantity
      }))
    },
    updateQuantity({ productId, quantity }) {
      this.$emit('updateQuantity', { productId, quantity })
    }
  },
  computed: {
    ...mapState('auth', ['currencyCode']),
    total() {
      return this.products
        .map(product => product.fullPrice * product.quantity)
        .reduce((total, value) => total + value)
    }
  },
  components: {
    CheckoutSummary,
    CheckoutContact,
    CheckoutDone,
    CheckoutError,
    CheckoutProcessing
  }
}
</script>

<style scoped>
.transparent-background {
  background-color: rgba(30, 32, 31, 0.9);
}
</style>
