<template>
  <div
    class="absolute top-0 right-0 z-20 bg-welcome bg-cover bg-center h-screen w-full flex items-end p-10"
  >
    <div
      class="absolute top-0 right-0 transparent-background h-screen w-full flex justify-center items-center"
    >
      <div
        class="py-16 rounded-big sm:w-3/4 w-full mx-4 bg-white flex flex-col justify-center items-center"
      >
        <icon class="idle-icon mb-6" name="idle-clock" />
        <div class="font-title uppercase text-center text-4xl">
          Tu sesión va a caducar en
        </div>
        <div class="text-red uppercase text-center text-6xl font-bold">
          00:{{ secondsFormatted }}
        </div>
        <div class="flex">
          <button
            class="my-6 mx-4 px-6 py-3 rounded-small uppercase font-title border border-gray"
            @click="$router.push({ name: 'welcome' })"
          >
            Cerrar sesión
          </button>
          <button
            class="my-6 px-6 py-3 bg-red text-white rounded-small uppercase font-title"
            @click="$emit('close')"
          >
            Seguir comprando
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon'
export default {
  name: 'IdleModal',
  components: {
    Icon
  },
  data: () => ({
    seconds: process.env.VUE_APP_IDlE_MODAL_TIMEOUT,
    interval: null
  }),
  computed: {
    secondsFormatted() {
      return `${this.seconds > 9 ? '' : '0'}${this.seconds}`
    }
  },
  mounted() {
    this.interval = window.setInterval(() => {
      --this.seconds
      if (this.seconds === 0) {
        this.$router.push({ name: 'welcome' })
      }
    }, 1000)
  },
  beforeDestroy() {
    window.clearInterval(this.interval)
  },
  methods: {
    closeSession() {
      this.$router.push({ name: 'welcome' })
    }
  }
}
</script>

<style lang="scss" scoped>
.bg-welcome {
  background-image: url('../assets/welcome-bg.png');
}
.transparent-background {
  backdrop-filter: blur(10px);
  background-color: rgba(30, 32, 31, 0.2);
}

.font-big {
  font-size: 44px;
}

.idle-icon {
  width: 116px;
  height: 116px;
}
</style>
