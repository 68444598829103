<template>
  <div
    class="absolute top-0 h-screen w-full z-20 transparent-background overflow-hidden"
  >
    <icon
      name="close"
      class="text-white float-right m-6 relative z-20"
      @click.native="$emit('close')"
    />
    <div
      class="mx-32 flex flex-col items-center overflow-scroll scrolling-touch h-full hide-scroll smooth"
      v-if="editingProduct"
      id="groups"
      ref="groups"
    >
      <div style="height: 50%" class="flex-none" />
      <combo-category
        v-for="(category, index) in filteredCategories"
        :key="category.id"
        @next="scrollTo(index + 1)"
        :id="'group-' + index"
        :category="category"
        :products.sync="selectedProducts[index]"
        :show-next="index + 1 < product.categories.length"
      />
      <div
        class="m-6 px-6 py-3 bg-red text-white rounded-small uppercase add-button"
        @click="saveProduct"
        :class="{ 'opacity-50 pointer-events-none': !canSaveProduct }"
      >
        {{ isEditing ? 'Guardar Cambios' : 'Agregar Producto' }}
      </div>
      <div style="height: 50%" class="flex-none" />
    </div>
    <div class="bottom-gradient absolute bottom-0 w-full z-10" />
    <div class="top-gradient absolute top-0 w-full z-10" />
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
import ComboCategory from '@/components/ComboCategory.vue'
import { debounce } from 'throttle-debounce'

export default {
  name: 'ComboEditor',
  props: {
    product: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      activeGroupId: null,
      selectedProducts: [],
      editingProduct: {},
      scrollListener: debounce(100, this.onScroll)
    }
  },
  mounted() {
    if (!this.product) return
    this.editingProduct = {
      ...this.product,
      quantity: this.product.quantity || 1
    }
    this.updateSelectedProducts()
    this.activeGroupId = this.product.categories[0].id
    this.$nextTick(() => {
      this.$refs.groups.addEventListener('scroll', this.scrollListener)
      this.scrollTo(0)
    })
  },
  beforeDestroy() {
    this.$refs.groups.removeEventListener('scroll', this.scrollListener)
  },
  methods: {
    scrollTo(position) {
      if (position >= this.product.categories.length) return
      let scrollContainer = this.$refs.groups
      let middle = scrollContainer.getBoundingClientRect().height / 2
      let group = document.getElementById(`group-${position}`)
      let centerTop =
        group.getBoundingClientRect().y -
        middle +
        group.getBoundingClientRect().height / 2
      let realTop = group.getBoundingClientRect().y - 100
      let y = Math.min(centerTop, realTop)
      this.activeGroupId = this.product.categories[position].id
      scrollContainer.scrollBy({ top: y, behavior: 'smooth' })
    },
    onScroll() {
      this.activeGroupId = this.getItemInsideWindow()
    },
    getItemInsideWindow() {
      let ids = this.product.categories.map(group => group.id)
      let scrollContainer = this.$refs.groups
      let middle = scrollContainer.getBoundingClientRect().height / 2
      let distances = ids.map((id, index) => {
        const target = document.getElementById('group-' + index)
        if (!target) return null
        let box = target.getBoundingClientRect()
        let dist = Math.min(
          Math.abs(box.y - middle),
          Math.abs(box.y + box.height - middle)
        )
        return { id, dist }
      })
      distances.sort((a, b) => a.dist - b.dist)
      return distances[0].id
    },
    updateSelectedProducts() {
      this.selectedProducts = Array.from(
        { length: this.editingProduct.categories.length },
        () => ({})
      )
      if (this.editingProduct.comboProducts) {
        let productIndexes = this.editingProduct.categories
          .flatMap((category, index) =>
            category.products.map(product => ({ id: product.id, index }))
          )
          .reduce((res, product) => {
            res[product.id] = product.index
            return res
          }, {})
        this.editingProduct.comboProducts.forEach(product => {
          let index = productIndexes[product.id]
          this.selectedProducts[index][product.id] = {
            validModifiers: false,
            ...product
          }
        })
      }
    },
    saveProduct() {
      if (!this.canSaveProduct) {
        return
      }
      this.$emit('addProduct', {
        ...this.product,
        comboProducts: this.editingProductComboProducts
      })
    }
  },
  computed: {
    offset() {
      return -(this.$el.clientHeight / 2 - 100)
    },
    filteredCategories() {
      return this.editingProduct?.categories?.filter(c => c.enabled) || []
    },
    isEditing() {
      return !!this.product.catalogId
    },
    canSaveProduct() {
      if (!this.product) {
        return false
      }
      return this.filteredCategories.every((category, index) => {
        let validModifiers = Object.values(
          this.selectedProducts[index] || {}
        ).every(product => product.validModifiers)
        let totalProducts = Object.values(
          this.selectedProducts[index] || {}
        ).reduce((total, product) => total + product.quantity, 0)
        let minCheck = totalProducts >= (category.min || 0)
        let maxCheck = !category.max || totalProducts <= category.max
        return validModifiers && minCheck && maxCheck
      })
    },
    editingProductComboProducts() {
      let products = (this.editingProduct.categories || [])
        .flatMap(category => category.products)
        .reduce((res, product) => {
          res[product.id] = product
          return res
        }, {})
      return this.selectedProducts.flatMap(categoryProducts =>
        Object.keys(categoryProducts).map(productId => ({
          ...products[productId],
          quantity: categoryProducts[productId].quantity,
          modifiers: categoryProducts[productId].modifiers
        }))
      )
    }
  },
  components: {
    Icon,
    ComboCategory
  }
}
</script>

<style scoped>
.smooth {
  scroll-behavior: smooth;
}
.transparent-background {
  background-color: rgba(30, 32, 31, 0.9);
}
.bg-red {
  background: #dd3333;
}
.clear-button:disabled {
  @apply bg-gray-200;
  @apply text-gray-400;
}
.finish-button:disabled {
  @apply opacity-50;
}
.bottom-gradient {
  pointer-events: none;
  height: 10%;
  background-image: linear-gradient(
    to top,
    rgba(30, 32, 31, 0.9),
    rgba(30, 32, 31, 0)
  );
}
.top-gradient {
  pointer-events: none;
  height: 10%;
  background-image: linear-gradient(
    to bottom,
    rgba(30, 32, 31, 0.9),
    rgba(30, 32, 31, 0)
  );
}
.hide-scroll::-webkit-scrollbar {
  display: none;
}
</style>
