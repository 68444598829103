<template>
  <div class="w-full">
    <div class="flex w-full px-10 items-center bg-white py-4">
      <div class="flex-1">
        <div class="flex justify-between items-center">
          <div class="font-title text-accent text-xl uppercase mb-2">
            {{ product.name }}
          </div>
        </div>
        <div class="text-gray-400 flex">
          {{ modifiers }}
        </div>
      </div>
      <div class="flex justify-between items-center min-w">
        <quantity-selector
          :quantity="product.quantity"
          @update:quantity="updateQuantity"
        />
        <div class="text-2xl text-gray-900 font-bold font-title px-10">
          {{ product.fullPrice | currency }}
        </div>
        <div
          class="rounded-full w-6 h-6 bg-accent p-small"
          @click="deleteProduct"
        >
          <icon name="close" class="w-full h-full text-white" />
        </div>
      </div>
    </div>
    <dotted-line :with-ends="true" />
  </div>
</template>

<script>
import QuantitySelector from './QuantitySelector.vue'
import DottedLine from './DottedLine.vue'
import Icon from '@last/core-ui/components/Icon.vue'

export default {
  name: 'CheckoutProduct',
  props: {
    product: {
      type: Object,
      default: null
    }
  },
  methods: {
    updateQuantity(quantity) {
      this.$emit('updateQuantity', quantity)
    },
    deleteProduct() {
      this.$emit('updateQuantity', 0)
    }
  },
  computed: {
    modifiers() {
      return this.product.modifiers
        .map(modifier => {
          let quantity = modifier.quantity > 1 ? ` x ${modifier.quantity}` : ''
          return modifier.name + quantity
        })
        .join(', ')
    }
  },
  components: {
    QuantitySelector,
    Icon,
    DottedLine
  }
}
</script>

<style scoped>
.card {
  width: 340px;
}
.clear-button:disabled {
  @apply bg-gray-200;
  @apply text-gray-400;
}
.finish-button:disabled {
  @apply opacity-50;
}
.p-small {
  padding: 6px;
}
.min-w {
  width: 270px;
}
</style>
