<template>
  <div class="login-container">
    <div class="login-box">
      <img class="logo" src="../assets/logo-big.png" />
      <div class="field">
        <label class="label">Email</label>
        <div class="control">
          <l-input
            type="text"
            placeholder="manager@store.com"
            v-model="email"
          />
        </div>
      </div>
      <div class="field">
        <label class="label">Password</label>
        <l-input type="password" v-model="password" />
      </div>
      <div class="center field">
        <l-button @click="startLogin"> Login </l-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LInput from '@last/core-ui/components/LInput.vue'
import LButton from '@last/core-ui/components/LButton.vue'

export default {
  name: 'Login',
  data() {
    return {
      email: null,
      password: null,
      loggingIn: false
    }
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push({ name: 'welcome' })
    }
  },
  methods: {
    ...mapActions('auth', ['login']),
    async startLogin() {
      this.loggingIn = true
      try {
        this.login({ email: this.email, password: this.password })
      } catch (error) {
        // show error
      }
      this.loggingIn = false
    }
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated'])
  },
  watch: {
    isAuthenticated(authenticated) {
      if (authenticated) {
        this.$router.push({ name: 'welcome' })
      }
    }
  },
  components: {
    LInput,
    LButton
  }
}
</script>

<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.login-box {
  width: 300px;
}

.logo {
  margin-left: 50px;
  width: 200px;
}

.login-button {
  margin-top: 20px;
  padding: 0 70px;
}

.center {
  display: flex;
  justify-content: center;
}

.sign-up {
  font-size: 15px;
}
</style>
