<template>
  <div
    :class="{ 'hidden-below': !hasProducts }"
    class="transition bg-white rounded-t-big cart shadow-lg flex flex-col"
  >
    <div class="flex justify-between items-center">
      <div class="text-3xl font-title uppercase px-6 pt-6">Tu Pedido</div>
    </div>
    <div class="py-4 overflow-x-scroll scrolling-touch" ref="scroll">
      <transition-group name="list" tag="div" class="flex">
        <cart-product
          class="transition ml-6 card flex-shrink-0"
          v-for="product in products"
          :key="product.id"
          :product="product"
          @edit="$emit('edit', product)"
          @updateQuantity="quantity => updateQuantity(product.id, quantity)"
        />
        <div class="p-4" key="separator" />
      </transition-group>
    </div>
    <div class="flex justify-between px-8 pb-8 items-center">
      <div
        class="flex justify-end items-baseline text-3xl font-title text-accent uppercase"
      >
        <div class="pr-4">Total:</div>
        {{ total | currency }}
      </div>
      <div class="flex">
        <button
          class="mr-6 px-12 py-3 bg-black text-white rounded-small uppercase clear-button transition font-title text-xl"
          :disabled="!hasProducts"
          @click="$emit('clear')"
        >
          Vaciar Pedido
        </button>
        <button
          class="px-12 py-3 bg-accent text-white rounded-small uppercase finish-button transition font-title text-xl"
          :disabled="!hasProducts"
          @click="$emit('finalize')"
        >
          Finalizar Pedido
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CartProduct from './CartProduct.vue'

export default {
  name: 'Cart',
  props: {
    products: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    updateQuantity(productId, quantity) {
      this.$emit('updateQuantity', { productId, quantity })
    }
  },
  computed: {
    hasProducts() {
      return this.products.length > 0
    },
    total() {
      return this.products
        .map(product => product.fullPrice)
        .reduce((total, value) => total + value, 0)
    }
  },
  watch: {
    products(newProducts, oldProducts) {
      if (newProducts.length > oldProducts.length) {
        this.$nextTick(() => {
          var container = this.$refs.scroll
          container.scrollBy({
            left: container.scrollWidth,
            behavior: 'smooth'
          })
        })
      }
    }
  },
  components: {
    CartProduct
  }
}
</script>

<style scoped>
.cart {
  box-shadow: -8px 12px 18px 0 rgba(30, 32, 31, 0.4);
}

.clear-button:disabled {
  @apply bg-gray-200;
  @apply text-gray-400;
}
.finish-button:disabled {
  @apply opacity-50;
}
.transition {
  transition: all 0.3s;
}
.list-leave-active {
  width: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.hidden-below {
  transform: translateY(100%);
}
</style>
