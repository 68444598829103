<template>
  <button
    :class="classes"
    class="rounded-small text-sm focus:outline-none flex items-center"
    @click="$emit('click')"
  >
    <icon v-if="icon" :name="icon" class="mr-3 -ml-3" />
    <slot />
  </button>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'

export default {
  name: 'LButton',
  props: {
    small: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    }
  },
  computed: {
    classes() {
      let sizeClasses
      if (this.small) {
        sizeClasses = ['px-10', 'py-2']
      } else {
        sizeClasses = ['px-6', 'py-3']
      }
      let styleClasses
      if (this.border) {
        styleClasses = [
          'border',
          'border-gray-400',
          'hover:border-0',
          'text-white'
        ]
      } else {
        styleClasses = ['bg-lblue-500', 'text-white']
      }
      return [...styleClasses, ...sizeClasses]
    }
  },
  components: {
    Icon
  }
}
</script>
