import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth.js'
import config from './config.js'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    auth,
    config
  },
  strict: debug
})
