<template>
  <div class="card shadow rounded-big p-4 relative line-height">
    <div
      class="absolute rounded-full w-6 h-6 bg-black p-small m-3 top-0 right-0"
      @click.stop="deleteProduct"
    >
      <icon name="close" class="w-full h-full text-white" />
    </div>
    <div class="flex flex-col h-full">
      <div
        @click="
          ;(product.modifierGroups || product.comboProducts) &&
            (product.modifierGroups.length > 0 ||
              product.comboProducts.length > 0) &&
            $emit('edit')
        "
        class="flex justify-between items-center"
      >
        <div class="font-title text-accent text-xl uppercase mb-2">
          {{ product.name }}
        </div>
      </div>
      <div
        @click="
          product.modifierGroups &&
            product.modifierGroups.length > 0 &&
            $emit('edit')
        "
        class="text-gray-400 flex-1 pt-2 overflow-y-scroll scrolling-touch"
      >
        {{ modifiers }}
      </div>
      <div
        @click="
          product.comboProducts &&
            product.comboProducts.length > 0 &&
            $emit('edit')
        "
        class="text-gray-400 flex-1 pt-2 overflow-y-scroll scrolling-touch"
      >
        {{ comboProducts }}
      </div>
      <div class="mt-6 flex justify-between items-center">
        <quantity-selector
          :quantity="product.quantity"
          @update:quantity="updateQuantity"
        />
        <div class="text-2xl text-gray-900 font-bold font-title">
          {{ product.fullPrice | currency }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuantitySelector from './QuantitySelector.vue'
import Icon from '@last/core-ui/components/Icon.vue'

export default {
  name: 'CartProduct',
  props: {
    product: {
      type: Object,
      default: null
    }
  },
  methods: {
    updateQuantity(quantity) {
      this.$emit('updateQuantity', quantity)
    },
    deleteProduct() {
      this.$emit('updateQuantity', 0)
    }
  },
  computed: {
    modifiers() {
      if (this.product.modifiers) {
        return this.product.modifiers
          .map(modifier => {
            let quantity =
              modifier.quantity > 1 ? ` x ${modifier.quantity}` : ''
            return modifier.name + quantity
          })
          .join(', ')
      }
      return ''
    },
    comboProducts() {
      if (this.product.comboProducts) {
        return this.product.comboProducts
          .map(product => {
            let quantity = product.quantity > 1 ? ` x ${product.quantity}` : ''
            return product.name + quantity
          })
          .join(', ')
      }
      return ''
    }
  },
  components: {
    QuantitySelector,
    Icon
  }
}
</script>

<style scoped>
.card {
  width: 341px;
  height: 160px;
  overflow: hidden;
}
.clear-button:disabled {
  @apply bg-gray-200;
  @apply text-gray-400;
}
.finish-button:disabled {
  @apply opacity-50;
}
.p-small {
  padding: 6px;
}
.line-height {
  line-height: 17px;
}
</style>
