<template>
  <div class="w-full py-5">
    <div class="text-gray-500">
      {{ modifierGroup.name }}
    </div>
    <div class="flex justify-between items-center">
      <div class="text-xs text-gray-500">{{ subtitle }}</div>
      <div class="text-xs text-red" v-if="modifierGroup.min">*obligatorio</div>
    </div>
    <div class="mt-5">
      <div
        v-for="modifier in filteredModifiers"
        :key="modifier.id"
        class="py-2 flex items-center justify-between font-body text-sm cursor-pointer rounded-small -mx-4 px-4"
        @click="toggle(modifier.id)"
        :class="{
          'bg-gray-100': isSelected(modifier.id),
          'text-gray-300 pointer-events-none':
            isMaxed && multiple && !isSelected(modifier.id)
        }"
      >
        <div class="flex items-center">
          <l-checkbox
            v-if="multiple"
            :value="isSelected(modifier.id)"
            @input="toggle(modifier.id)"
            :class="{
              'opacity-25': isMaxed && multiple && !isSelected(modifier.id)
            }"
          />
          <l-radio
            v-else
            :value="isSelected(modifier.id)"
            @input="toggle(modifier.id)"
          />
          <div class="ml-4">{{ modifier.name }}</div>
        </div>
        <div class="flex items-center">
          <div class="text-sm text-accent" v-if="modifier.priceImpact > 0">
            (+{{ modifier.priceImpact | currency }})
          </div>
          <l-quantity-selector
            class="ml-4"
            v-if="modifierGroup.allowRepeat && isSelected(modifier.id)"
            :quantity="modifiers[modifier.id]"
            @update:quantity="quantity => updateQuantity(modifier.id, quantity)"
            :is-maxed="isMaxed"
            :style-prop="'secondary'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LRadio from '@last/core-ui/components/LRadio.vue'
import LCheckbox from '@last/core-ui/components/LCheckbox.vue'
import LQuantitySelector from '@last/core-ui/components/LQuantitySelector.vue'

export default {
  name: 'ModifierGroupSection',
  props: {
    modifierGroup: {
      type: Object,
      default: null
    },
    modifiers: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    toggle(id) {
      if (this.disabled) return
      if (this.multiple) {
        if (id in this.modifiers) {
          let modifiers = {
            ...this.modifiers
          }
          delete modifiers[id]
          this.$emit('update:modifiers', modifiers)
        } else {
          this.$emit('update:modifiers', { ...this.modifiers, [id]: 1 })
        }
      } else {
        this.$emit('update:modifiers', { [id]: 1 })
      }
    },
    isSelected(id) {
      if (!this.modifiers) {
        return false
      }
      return id in this.modifiers
    },
    updateQuantity(id, quantity) {
      let modifiers = {
        ...this.modifiers
      }
      modifiers[id] = quantity
      this.$emit('update:modifiers', modifiers)
    }
  },
  computed: {
    filteredModifiers() {
      return this.modifierGroup.modifiers.filter(m => m.enabled)
    },
    subtitle() {
      let subtitle = 'Seleccionar'
      if (!this.multiple) {
        return subtitle + ' una opción'
      }
      if (this.modifierGroup.min) {
        subtitle += ` un mínimo de ${this.modifierGroup.min}`
      }
      if (this.modifierGroup.min && this.modifierGroup.max) {
        subtitle += ' y'
      }
      if (this.modifierGroup.max) {
        subtitle += ` un máximo de ${this.modifierGroup.max}`
      }
      return subtitle + ' opciones'
    },
    multiple() {
      let min = this.modifierGroup.min || 0
      let max = this.modifierGroup.max
      if (!this.modifierGroup.max) return true
      if (min > 1) return true
      return max - min > 0
    },
    totalModifiers() {
      if (!this.modifiers) {
        return 0
      }
      return Object.values(this.modifiers).reduce(
        (res, quantity) => res + quantity,
        0
      )
    },
    isMaxed() {
      return (
        this.modifierGroup.max && this.totalModifiers === this.modifierGroup.max
      )
    }
  },
  components: {
    LRadio,
    LCheckbox,
    LQuantitySelector
  }
}
</script>

<style scoped></style>
