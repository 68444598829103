<template>
  <div>
    <div
      class="h-screen w-full flex flex-col bg-gray-200"
      :class="{ blur: modifyingProduct || modifyingCombo || showingCheckout }"
    >
      <div class="flex-1 flex flex-col overflow-hidden">
        <div :class="{ 'opacity-0': !ready }" class="bg-gray-900 transition">
          <div class="pt-4 px-4 flex justify-between text-white items-center">
            <div class="menu-title font-title">Nuestro Menú</div>
            <l-button
              icon="trash"
              border
              @click="$router.push({ name: 'welcome' })"
              >CANCELAR PEDIDO</l-button
            >
          </div>
          <categories
            :categories="categoryList"
            :selected-id="selectedCategoryId"
            @select="id => (selectedCategoryId = id)"
          />
        </div>
        <div
          :class="{ 'cart-padding': hasProducts, 'move-down': !ready }"
          class="bg-gray-200 flex-1 overflow-scroll scrolling-touch px-10 transition-products opacity-100"
        >
          <div class="title font-title pt-12 pb-4 px-6 uppercase">
            {{ selectedCategory.name }}
          </div>
          <div class="flex flex-wrap">
            <product-card
              v-for="product in selectedCategory.products"
              :key="product.id"
              :product="product"
              @add="addProduct"
            />
          </div>
        </div>
        <cart
          class="fixed w-screen bottom-0 left-0 right-0 z-10"
          :products="cartProducts"
          @edit="editProduct"
          @updateQuantity="updateQuantity"
          @clear="clear"
          @finalize="showingCheckout = true"
        />
      </div>
    </div>
    <modifiers-selector
      v-if="modifyingProduct"
      :product="modifyingProduct"
      @close="modifyingProduct = null"
      @addProduct="addProductToCart"
    />
    <combo-editor
      v-if="modifyingCombo"
      :product="modifyingCombo"
      @close="modifyingCombo = null"
      @addProduct="addProductToCart"
    />
    <checkout
      v-if="showingCheckout"
      :products="cartProducts"
      @updateQuantity="updateQuantity"
      @close="showingCheckout = false"
    />
    <idle-modal v-if="idleActive" @close="deactivateIdle" />
  </div>
</template>

<script>
import { normalize, schema } from 'normalizr'
import uuid4 from 'uuid/v4'
import idle from '@/idle'
import IdleModal from '@/components/IdleModal'
import api from '@/api.js'
import LButton from '@/components/LButton.vue'
import Categories from '@/components/Categories.vue'
import ProductCard from '@/components/ProductCard.vue'
import ModifiersSelector from '@/components/ModifiersSelector.vue'
import ComboEditor from '@/components/ComboEditor.vue'
import ProductPriceCalculator from '@last/core/src/productPriceCalculator.js'
import Cart from '@/components/Cart.vue'
import Checkout from '@/components/Checkout.vue'
const categorySchema = new schema.Entity('categories')

export default {
  name: 'Ordering',
  mixins: [idle],
  components: {
    LButton,
    Categories,
    ProductCard,
    Cart,
    ModifiersSelector,
    ComboEditor,
    Checkout,
    IdleModal
  },
  data() {
    return {
      categories: {},
      categoryIds: [],
      selectedCategoryId: null,
      modifyingProduct: null,
      modifyingCombo: null,
      cart: [],
      products: {},
      showingCheckout: false
    }
  },
  async mounted() {
    await this.refreshCatalog()
  },
  methods: {
    async refreshCatalog() {
      let response = await api.get('/catalog')
      let catalog = normalize(response.data, [categorySchema])
      this.categories = catalog.entities.categories
      this.categoryIds = catalog.result
      this.selectedCategoryId = this.categoryIds[0]
    },
    addProduct(product) {
      if (product.categories?.length > 0) {
        this.modifyingCombo = product
      } else if (product.modifierGroups?.length > 0) {
        this.modifyingProduct = product
      } else {
        this.addProductToCart(product)
      }
    },
    addProductToCart(product) {
      this.modifyingProduct = null
      this.modifyingCombo = null
      if (product.catalogId) {
        this.products[product.id] = product
      } else {
        let cartProduct = {
          ...product,
          catalogId: product.id,
          id: uuid4(),
          quantity: 1,
          modifiers: product.modifiers || []
        }
        this.products = {
          ...this.products,
          [cartProduct.id]: cartProduct
        }
        this.cart = [...this.cart, cartProduct.id]
      }
    },
    updateQuantity({ productId, quantity }) {
      if (quantity === 0) {
        this.cart = this.cart.filter(id => id != productId)
        this.$delete(this.products, productId)
        if (this.cart.length === 0) this.showingCheckout = false
      } else {
        this.products[productId].quantity = quantity
      }
    },
    clear() {
      this.cart = []
      this.products = {}
    },
    editProduct(product) {
      if (product.type === 'COMBO') {
        this.modifyingCombo = product
      } else {
        this.modifyingProduct = product
      }
    }
  },
  computed: {
    selectedCategory() {
      return this.categories[this.selectedCategoryId] || {}
    },
    categoryList() {
      return this.categoryIds.map(categoryId => ({
        id: categoryId,
        name: this.categories[categoryId].name
      }))
    },
    cartProducts() {
      return this.cart.map(productId => {
        return {
          ...this.products[productId],
          ...ProductPriceCalculator.calculateProductPricing(
            this.products[productId]
          )
        }
      })
    },
    hasProducts() {
      return this.cart.length > 0
    },
    ready() {
      return this.categoryIds.length > 0
    }
  }
}
</script>

<style scoped>
.menu-title {
  font-size: 30px;
  text-transform: uppercase;
}

.title {
  font-size: 44px;
}

.selected {
  background: #dd3333;
  color: white;
}

.cart-padding {
  padding-bottom: 380px;
}

.blur {
  filter: blur(20px);
}

.transition {
  transition: all 0.3s;
}

.transition-products {
  transition: transform 0.3s, opacity 0.3s;
  transition-delay: 0.2s;
}

.move-down {
  transform: translateY(100px);
  opacity: 0;
}
</style>
