<template>
  <div class="w-full">
    <div
      ref="scroll"
      class="w-full py-4 flex items-center overflow-scroll scrolling-touch hide-scroll min-h transition"
    >
      <div
        v-for="category in categories"
        :key="category.id"
        class="border rounded-small category uppercase flex-none flex items-center justify-center p-4 text-center ml-4 cursor-pointer border-accent bg-white font-title"
        :class="{ 'selected bg-accent': category.id === selectedId }"
        @click="$emit('select', category.id)"
      >
        {{ category.name }}
      </div>
      <div class="p-2" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Categories',
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    selectedId: {
      type: String,
      default: null
    }
  },
  methods: {
    scroll() {
      var container = this.$refs.scroll
      container.scrollTo({ left: 500 })
      container.scrollTo({ left: 0, behavior: 'smooth' })
    }
  },
  watch: {
    categories() {
      this.$nextTick(() => {
        this.scroll()
      })
    }
  }
}
</script>

<style scoped>
.category {
  font-size: 22px;
  min-width: 180px;
  width: 210px;
  height: 106px;
  line-height: 24px;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.selected {
  color: white;
}

.min-h {
  min-height: 138px;
}

.translate {
  transform: translateX(-1000px);
}

.transition {
  transition: all 1s;
  transition-delay: 0.2s;
  transition-timing-function: cubic-bezier(0.33, 0.63, 0.42, 0.92);
}
</style>
