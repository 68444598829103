<template>
  <div
    class="rounded-big w-full bg-white p-10 my-5 card relative transition"
    :class="{ disabled: disabled }"
  >
    <div class="font-title text-xl uppercase">{{ modifierGroup.name }}</div>
    <div class="flex justify-between items-center">
      <div class="text-sm text-gray-400">{{ subtitle }}</div>
      <div class="text-sm text-red" v-if="modifierGroup.min">*obligatorio</div>
    </div>
    <div class="pt-6">
      <div
        class="rounded-small border-gray-400 p-4 mb-4 flex items-center justify-between"
        @click="toggle(modifier.id)"
        :class="{
          'border border-red': isSelected(modifier.id),
          border: !isSelected(modifier.id),
          'text-gray-300 pointer-events-none':
            isMaxed && multiple && !isSelected(modifier.id)
        }"
        v-for="modifier in modifierGroup.modifiers"
        :key="modifier.id"
      >
        <div class="flex items-center">
          <l-checkbox
            v-if="multiple"
            :value="isSelected(modifier.id)"
            @input="toggle(modifier.id)"
          />
          <l-radio
            v-else
            :value="isSelected(modifier.id)"
            @input="toggle(modifier.id)"
          />
          <div class="ml-4">{{ modifier.name }}</div>
        </div>
        <div class="flex items-center">
          <div class="text-sm text-red" v-if="modifier.priceImpact > 0">
            (+{{ modifier.priceImpact | currency }})
          </div>
          <quantity-selector
            class="ml-4"
            v-if="modifierGroup.allowRepeat && isSelected(modifier.id)"
            :quantity="modifiers[modifier.id]"
            @update:quantity="quantity => updateQuantity(modifier.id, quantity)"
            :is-maxed="isMaxed"
          />
        </div>
      </div>
    </div>
    <transition name="grow">
      <div
        class="w-full absolute bottom-0 left-0 -mb-6 flex justify-center"
        v-if="canContinue && showNext"
      >
        <div
          class="bg-red w-12 h-12 rounded-full flex justify-center items-center"
          @click.stop="$emit('next')"
        >
          <icon name="arrow-down" class="text-white" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import LCheckbox from '@last/core-ui/components/LCheckbox.vue'
import LRadio from '@last/core-ui/components/LRadio.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import QuantitySelector from './QuantitySelector.vue'

export default {
  name: 'ModifierGroupCard',
  props: {
    modifierGroup: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showNext: {
      type: Boolean,
      default: false
    },
    modifiers: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    toggle(id) {
      if (this.disabled) return
      if (this.multiple) {
        if (id in this.modifiers) {
          let modifiers = {
            ...this.modifiers
          }
          delete modifiers[id]
          this.$emit('update:modifiers', modifiers)
        } else {
          this.$emit('update:modifiers', { ...this.modifiers, [id]: 1 })
        }
      } else {
        this.$emit('update:modifiers', { [id]: 1 })
      }
    },
    updateQuantity(id, quantity) {
      let modifiers = {
        ...this.modifiers
      }
      modifiers[id] = quantity
      this.$emit('update:modifiers', modifiers)
    },
    isSelected(id) {
      return id in this.modifiers
    }
  },
  computed: {
    multiple() {
      let min = this.modifierGroup.min || 0
      let max = this.modifierGroup.max
      if (!this.modifierGroup.max) return true
      if (min > 1) return true
      return max - min > 1
    },
    subtitle() {
      let subtitle = 'Seleccionar'
      if (!this.multiple) {
        return subtitle + ' una opción'
      }
      if (this.modifierGroup.min) {
        subtitle += ` un mínimo de ${this.modifierGroup.min}`
      }
      if (this.modifierGroup.min && this.modifierGroup.max) {
        subtitle += ' y'
      }
      if (this.modifierGroup.max) {
        subtitle += ` un máximo de ${this.modifierGroup.max}`
      }
      return subtitle + ' opciones'
    },
    totalModifiers() {
      return Object.values(this.modifiers).reduce(
        (res, quantity) => res + quantity,
        0
      )
    },
    canContinue() {
      return this.totalModifiers >= this.modifierGroup.min
    },
    isMaxed() {
      return (
        this.modifierGroup.max && this.totalModifiers === this.modifierGroup.max
      )
    }
  },
  watch: {
    isMaxed(isMaxed) {
      if (isMaxed) {
        this.$emit('next')
      }
    }
  },
  components: {
    LCheckbox,
    LRadio,
    Icon,
    QuantitySelector
  }
}
</script>

<style scoped>
.card {
  max-width: 750px;
  scroll-snap-align: center;
  scroll-padding-top: 250px;
  scroll-padding-bottom: 250px;
  -webkit-transform: translate3d(0, 0, 0);
}
.card.disabled {
  transform: scale(0.9);
  @apply opacity-50;
}
.bg-red {
  background: #dd3333;
}
.border-red {
  border-color: #dd3333;
}
.text-red {
  color: #dd3333;
}
.clear-button:disabled {
  @apply bg-gray-200;
  @apply text-gray-400;
}
.finish-button:disabled {
  @apply opacity-50;
}
.grow-enter-active,
.grow-leave-active {
  transition: all 0.3s;
  -webkit-transform: scale(1);
}
.grow-enter,
.grow-leave-to {
  transform: scale(0);
}
.transition {
  transition: all 0.3s;
}
</style>
