const state = {}

const getters = {
  customization: (_state, _getters, rootState) => {
    let organizationId = rootState.auth.organizationId
    if (organizationId === '7ace4ad3-e0dd-4b07-b30b-59011e5a992d') {
      return {
        backgroundImageUrl: '/img/cotorrita-bg.jpg',
        logoUrl: '/img/cotorrita-logo.png',
        fontFamily: 'Roboto',
        accentColor: '#5f4318'
      }
    } else {
      return {
        backgroundImageUrl: '/img/orimaki-bg.png',
        doneBackgroundImageUrl: '/img/orimaki-done-bg.png',
        logoUrl: '/img/orimaki-logo.svg',
        fontFamily: 'Gilbert',
        accentColor: '#dd3333'
      }
    }
  }
}

const actions = {}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
