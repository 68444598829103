<template>
  <div class="card m-3">
    <div class="h-full rounded-big overflow-hidden bg-white relative">
      <div
        class="flex flex-col h-full transition z-10 relative"
        :class="{ 'transparent-background': showingDescription }"
      >
        <div
          class="transition z-10 p-2 flex justify-end"
          @click="showingDescription = true"
          :class="{
            'image-height': !showingDescription,
            'description-top': showingDescription
          }"
        >
          <transition name="fade">
            <icon
              @click.native.stop="showingDescription = false"
              v-if="showingDescription"
              name="close"
              class="text-white"
            />
          </transition>
        </div>
        <div class="h-full p-4 flex-1 flex flex-col relative z-10">
          <div
            class="text-lg font-title transition uppercase line-h flex-shrink-0"
            :class="{
              'text-white max-name-h100': showingDescription,
              'max-name-h': !showingDescription
            }"
          >
            {{ product.name }}
          </div>
          <transition name="fade-delay">
            <div v-if="showingDescription">
              <div class="separator my-2" />
              <div
                class="text-sm text-white overflow-y-scroll scrolling-touch max-description-h"
              >
                {{ product.description }}
              </div>
            </div>
          </transition>
          <div class="flex-1 flex flex-col justify-end items-end">
            <transition name="fade">
              <div v-if="!showingDescription" class="font-bold text-2xl">
                {{ product.price | currency }}
              </div>
            </transition>
            <div class="w-full pt-2 relative">
              <transition name="fade">
                <div
                  v-if="!showingDescription"
                  class="bg-gray-900 p-2 rounded-small mr-2 absolute"
                  @click="showingDescription = true"
                >
                  <icon
                    name="eye"
                    class="text-white transition"
                    :class="{
                      'opacity-0': showingDescription,
                      'opacity-100': !showingDescription
                    }"
                  />
                </div>
              </transition>
              <div
                @click="$emit('add', product)"
                class="flex-1 p-2 text-white bg-accent rounded-small font-title text-center transition z-10 relative uppercase"
                :class="{ 'button-margin': !showingDescription }"
              >
                Añadir
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="absolute top-0 left-0 z-0 w-full h-full">
        <l-image
          v-if="product.imageId"
          class="flex-1 p-4 bg-cover bg-center transition-size"
          :class="{
            'h-full': showingDescription,
            'image-height': !showingDescription
          }"
          :image-id="product.imageId"
          background
        />
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
import LImage from '@last/core-ui/components/LImage.vue'

export default {
  name: 'ProductCard',
  data() {
    return {
      showingDescription: false
    }
  },
  props: {
    product: {
      type: Object,
      default: null
    }
  },
  components: {
    Icon,
    LImage
  }
}
</script>

<style scoped>
.card {
  height: 346px;
  width: 206px;
  flex-shrink: 0;
}
.transition {
  transition: all 0.5s;
}
.transition-size {
  transition: height 0.5s;
}
.red {
  background: #dd3333;
  color: white;
}
.separator {
  height: 1px;
  width: 20%;
}
.image-height {
  height: 180px;
}
.description-top {
  height: 60px;
}
.transparent-background {
  background-color: rgba(30, 32, 31, 0.9);
}
.button-margin {
  margin-left: 45px;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-shrink-enter-active,
.fade-shrink-leave-active {
  transition: all 0.5s;
  width: 40px;
}
.fade-shrink-enter,
.fade-shrink-leave-to {
  opacity: 0;
  width: 0;
  padding: 0.5rem 0;
  margin: 0;
}
.fade-delay-enter-active {
  transition: all 0.5s;
  transition-delay: 0.3s;
  max-height: 200px;
}
.fade-delay-leave-active {
  transition: all 0.3s;
}
.fade-delay-enter,
.fade-delay-leave-to {
  opacity: 0;
  max-height: 0;
}
.max-name-h {
  max-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.max-name-h100 {
  max-height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.line-h {
  line-height: 17px;
}
.max-description-h {
  max-height: 160px;
}
</style>
